<template>
  <div>
    <b-row>
      <b-col>
        <b-card>

          <b-row>
            <h4 class="ml-1 mb-2">
              Data Rencana Perawatan
            </h4>
             <b-col cols="12">
              <label>NOPOL Kendaraan:</label>
              <p v-if="model.vehicle">{{ model.vehicle.number }}</p>
              <label>KM Rencana:</label>
              <p>{{ model.plan_km }} KM</p>
              <label>Deskripsi:</label>
              <p>{{ model.description }}</p>
              <label>Tanggal Rencana:</label>
              <p>{{ tanggal(model.plan_date) }}</p>
              <!-- <label>Rencana Biaya:</label>
              <p>{{ 'Rp'+formatPrice(model.plan_cost) }}</p> -->
              <label>Tanggal Realisasi:</label>
              <p>{{ tanggal(model.realisation_date) }} </p>
              <label>Biaya Realisasi:</label>
              <p>{{ 'Rp'+formatPrice(model.realisation_cost) }} </p>
              <label>Km Realisasi:</label>
              <p>{{model.realisation_km}} KM</p>
              <label>Tanggal Masuk:</label>
              <p>{{ tanggal(model.created_at) }}</p>
              <label>Bengkel:</label>
              <p>{{ model.is_external ? 'Eksternal' : 'Internal'}}</p>
              <hr>
            </b-col>
            <b-col cols="12" v-if="!model.is_external">
              <h4>Data Bengkel</h4>
              <label>Nama:</label>
              <p>{{ model.workshop?model.workshop.name:'-' }}</p>
              <label>Address:</label>
              <p>{{ model.workshop?model.workshop.address:'-' }}</p>
            </b-col>
            <b-col cols="12">
              <hr>
              <label v-if="model.author != null">Petugas Pelaporan:</label>
              <p v-if="model.author != null">
                {{ model.author.name }}
              </p>
              <label>Status {{ model.status }}</label>
              <br>
              <span
                v-if="model.status == 'request'"
                class="badge rounded-pill bg-info"
              >Permintaan Perawatan</span>
              <span
                v-if="model.status == 'plan'"
                class="badge rounded-pill bg-info"
              >Permintaan Perawatan Diterima</span>
              <span
                v-if="model.status == 'realisation'"
                class="badge rounded-pill bg-info"
              >Perawatan Sedang Diproses</span>
              <span
                v-if="model.status == 'reject'"
                class="badge rounded-pill bg-danger"
              >Permintaan Perawatan Ditolak</span>
              <span
                v-if="model.status == 'done'"
                class="badge rounded-pill bg-success"
              >Perawatan Selesai</span>
            </b-col>
            <b-col
              cols="12"
              class="mt-1"
            >
              <hr>
              <div v-if="loading">
                <b-spinner small />
                Loading...
              </div>
              <div v-else>
                <b-button
                  v-show="(model.status != 'request') ? false : true"
                  style="margin-right: 5px;"
                  variant="primary"
                  type="button"
                  @click.prevent="editData(model)"
                >
                  Edit
                </b-button>
                <b-button
                  v-show="(model.status != 'request' && model.status != 'plan') ? false : true"
                  style="margin-right: 5px;"
                  variant="danger"
                  type="button"
                  @click.prevent="confirmDelete(model)"
                >
                  Hapus
                </b-button>
                <!-- <b-button
                  v-show="(model.status != 'plan') ? false : true"
                  style="margin-right: 5px;"
                  variant="success"
                  type="button"
                  @click.prevent="showProses"
                >
                  Proses Rencana Perawatan
                </b-button> -->
                <!--  <b-button
                  v-show="(model.status == 'request') ? true : false"
                  style="margin-right: 5px;"
                  variant="success"
                  type="button"
                  @click.prevent="showTerima"
                >
                  Setujui
                </b-button> -->
                <!-- <b-button
                  style="margin-right: 5px;margin-top: 5px;"
                  variant="info"
                  type="button"
                  @click.prevent="cancel"
                >
                  Kembali
                </b-button> -->
              </div>
              
            </b-col>
          </b-row>
          <b-modal
            id="modal-center"
            ref="my-modal-setuju"
            :title="title_modal"
            centered
            hide-footer
          >
            <div class="form-group">
              <p>Apa anda ingin menerima Rencana Perawatan ini ?</p>
            </div>

            <div class="form-group mt-2 float-right">
              <b-button
                class
                variant="primary mr-1"
                @click="hideModal"
              >
                Batal
              </b-button>
              <b-button
                variant="warning"
                @click.prevent="submitTerima"
              >
                Terima Rencana Perawatan
              </b-button>
            </div>
          </b-modal>
          <b-modal
            id="modal-center"
            ref="my-modal-proses"
            :title="title_modal"
            centered
            hide-footer
          >
            <div class="form-group">
              <p>Apa anda ingin memproses Rencana Perawatan ini ?</p>
            </div>

            <div class="form-group mt-2 float-right">
              <b-button
                class
                variant="primary mr-1"
                @click="hideModal"
              >
                Batal
              </b-button>
              <b-button
                variant="warning"
                @click.prevent="submitProses"
              >
                Proses Rencana Perawatan
              </b-button>
            </div>
          </b-modal>
        </b-card>
      </b-col>
      <b-col>
        <b-card>
          <h4 class="mb-2"><feather-icon
            icon="ListIcon"
          /> Proses</h4>
          <view-time-line 
            :fields="data_time_line"
          />
        </b-card>
      </b-col>
    </b-row>
    <!-- <b-card>
      <b-row>
        <b-col cols="12">
          <b-tabs>
            <b-tab active>
              <template #title>
                <feather-icon icon="TruckIcon" />
                Daftar Perawatan
              </template>
              <b-table
                  striped
                  responsive
                  :items="itemPerawatan"
                  :fields="fieldsPerawatan"
              >
              <template #cell(cost)="data">
                <span>
                  {{ data.value ? 'Rp'+formatPrice(data.value) : '-' }}
                </span>
              </template> 
              <template #cell(realisation_cost)="data">
                <span>
                  {{ data.value ? 'Rp'+formatPrice(data.value) : '-' }}
                </span>
              </template>
              </b-table>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-card> -->
  </div>
</template>

<script>

import {BRow, BCol, BButton,BSpinner,BCard,BTabs, BTab, BTable,} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import FormLabel from '@/views/base/form/FormLabel.vue'
import ViewTimeLine from './ViewTimeLine.vue'
import moment from "moment"


export default {
  components: {

    BRow,
    BCol,
    BButton,
    BSpinner,
    BCard,
    FormLabel,
    ViewTimeLine,
    BTabs, BTab,
    BTable,
  },
  data() {
    return {
      loading :false,
      model:{},
      baseroute:'rencana-perawatan',
      posturl:'/maintenance',
      tombol_hide:true,
      ajukan:'',
      renderComp:true,
      title_modal:null,
      data_ajukan:[],
      data_item:{},
      data_time_line:{},
      itemPerawatan : null,
      fieldsPerawatan: [
        { key: 'type', label: 'Jenis Perawatan'},
        { key: 'name', label: 'Nama Perawatan'},
        { key: 'description', label: 'Deskripsi'},
        { key: 'cost', label: 'Biaya' },
        { key: 'realisation_cost', label: 'Biaya Realisasi' },
        { key: 'qty', label: 'Jumlah Item' },
        { key: 'realisation_qty', label: 'Jumlah Realisasi' },
      ],
    }
  },
  beforeMount(){
    this.$http.get('/staff?length=').then(ref=>{
        this.data_ajukan = ref.data.data
    })
  },
  mounted(){
    this.getData()
  },
  methods: {
    formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
     getData(){
        this.$http.get(this.posturl+'/'+this.$route.params.id).then(res=>{
          this.model = res.data
          this.itemPerawatan = []
          for (var detail of this.model.details){
            this.itemPerawatan.push({
              type: detail.activity_type == 'periksa' ? 'Pemeriksaan' : 
                      detail.activity_type == 'ganti' ? 'Penggantian' : 
                        detail.activity_type == 'tambah' ? 'Penambahan' : '-',
              name: detail.item.name,
              description: detail.item.description,
              cost: detail.cost,
              realisation_cost: detail.realisation_cost,
              qty: detail.qty ?? '-',
              realisation_qty: detail.realisation_qty ?? '-',
            });
          }
          this.data_time_line = res.data.tracks
        })
     },
    editData(data){
      this.$router.push({ name: this.baseroute+'-edit',params : { id: data.id} })
    },
    tanggal(date){
        return moment(date).local('id').format('DD-MM-YYYY')
      },
    confirmDelete(data){
      this.$bvModal
        .msgBoxConfirm(data.contract_subject != null ? 'Anda Ingin Menghapus Data '+data.contract_subject +' ini ? Karena akan berhubungan dengan data lain ?' : 'Anda Ingin Menghapus Data ini ? Karena akan berhubungan dengan data lain ?' , {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.delete(this.posturl+'/'+data.id)
              .then(() => {
                this.$router.push({ name: this.baseroute })

            })
            .catch(err=>{
              this.errorSubmit(err)
            })
          }
        })
    },
   showTerima(){
      this.title_modal = "Konfirmasi"
      this.$refs['my-modal-setuju'].show()
    },
    showProses(){
      this.title_modal = "Konfirmasi"
      this.$refs['my-modal-proses'].show()
    },
    submitTerima(){
      this.$http.put(this.posturl+'/'+this.$route.params.id+'/approve').then(()=>{
          this.getData()
          this.$refs['my-modal-setuju'].hide()
          this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Rencana perawatan berhasil diterima',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
    },
    submitProses(){
      let formData = {
            details:[]
      }
      for(var index in this.model.details){
        formData.details.push({
            id:this.model.details[index].id,
            realisation_cost:this.model.details[index].realisation_cost,
            realisation_qty:this.model.details[index].realisation_qty
        })
      }
      this.$http.put(this.posturl+'/'+this.$route.params.id+'/process',formData).then(()=>{
          this.getData()
          this.$refs['my-modal-proses'].hide()
          this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Rencana perawatan sedang diproses',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
    },
    hideModal(){
      this.$refs['my-modal-proses'].hide()
      this.$refs['my-modal-setuju'].hide()
    },
    cancel(){
        this.$router.push({ name:this.$route.meta.pageActive })
    },
    errorSubmit(err){
      const msg = err.response.data.message
      this.$refs['my-modal-form'].hide()
      this.$refs['my-modal-batalkan'].hide()
      this.$refs['my-modal-setuju'].hide()
      this.$refs['my-modal-kirim'].hide()
      this.$refs['my-modal-prepare'].hide()
      this.$refs['my-modal-terima'].hide()
      this.$bvToast.toast((msg)?msg:'Submit error', {
        title: 'Error',
        solid: true,
        variant:'danger'
      })
    },
  },
}
</script>
