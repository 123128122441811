<template>
  <app-timeline>
    <app-timeline-item
      v-for="data in fields"
      :key="data.status"
      :title="synStatus(data.status)"
      :subtitle="tanggal(data.created_at)+', oleh '+data.author.name"
      :variant="randomColor(warna)"
    />
  </app-timeline>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import moment from "moment"

  export default {
    components: {
      AppTimeline,
      AppTimelineItem,
    },
    props:{
      fields: {
          type: Array,
          default() {
            return []
          },
        },
    },
    data() {
      return {
        warna:['info','success']
      }
    },
    methods:{
      randomColor(item){
        return item[Math.floor(Math.random()*item.length)]
      },
      tanggal(date){
        return moment(date).local('id').format('DD-MMMM-YYYY')
      },
      synStatus(text){
        if(text == 'request'){
          var data = 'Permintaan Perawatan'
        }else if(text == 'realisation'){
          var data = 'Perawatan Sedang Diproses'
        }else if(text == 'reject'){
          var data = 'Permintaan Perawatan Ditolak'
        }else if(text == 'done'){
          var data = 'Perawatan Selesai'
        }else{
          var data = 'Permintaan Perawatan Diterima'
        }
        return data
      }
    }
    // created(){
    //   this.
    // }
  }
</script>

<style lang="scss" scoped>

</style>